define("suspet/templates/join", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PuJjbcSl",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[2,\"Joining \"],[1,[32,1]],[2,\"...\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "suspet/templates/join.hbs"
    }
  });

  _exports.default = _default;
});