define("suspet/utils/now", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = now;
  _exports.advance = advance;
  var previous = 0;

  function now() {
    var value = Math.round(performance.now() * 1000);

    if (value <= previous) {
      value = ++previous;
    } else {
      previous = value;
    }

    return value;
  }

  function advance(value) {
    previous = Math.max(value, previous);
  }
});