define("suspet/utils/color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isColor = isColor;
  _exports.default = void 0;
  var Color;

  (function (Color) {
    Color["Red"] = "red";
    Color["Blue"] = "blue";
    Color["Green"] = "green";
    Color["Pink"] = "pink";
    Color["Orange"] = "orange";
    Color["Yellow"] = "yellow";
    Color["Black"] = "black";
    Color["White"] = "white";
    Color["Purple"] = "purple";
    Color["Brown"] = "brown";
    Color["Cyan"] = "cyan";
    Color["Lime"] = "lime";
  })(Color || (Color = {}));

  var _default = Color;
  _exports.default = _default;

  function isColor(color) {
    return Object.values(Color).includes(color);
  }
});