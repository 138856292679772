define("suspet/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S1OGUU66",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"host\"]],[[\"default\"],[{\"statements\":[[2,\"Host\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"\\n  \"],[11,\"form\"],[4,[38,0],[\"submit\",[32,0,[\"join\"]]],null],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Game ID \"],[8,\"input\",[],[[\"@value\"],[[32,0,[\"gameId\"]]]],null],[13],[2,\"\\n    \"],[10,\"button\"],[14,4,\"submit\"],[12],[2,\"Join\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "suspet/templates/index.hbs"
    }
  });

  _exports.default = _default;
});