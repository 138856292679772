define("suspet/templates/lobby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1az8HCb5",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"lobby\",[],[[\"@lobby\"],[[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "suspet/templates/lobby.hbs"
    }
  });

  _exports.default = _default;
});