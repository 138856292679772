define("suspet/utils/random", ["exports", "suspet/utils/color"], function (_exports, _color) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gameId = gameId;
  _exports.color = color;
  var CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  function gameId() {
    var characters = [];

    for (var i = 0; i < 6; i++) {
      var index = Math.floor(Math.random() * CHARACTERS.length);
      characters.push(CHARACTERS.charAt(index));
    }

    return characters.join('');
  }

  var COLORS = Object.keys(_color.default);

  function color() {
    return _color.default[COLORS[Math.floor(Math.random() * COLORS.length)]];
  }
});