define("suspet/utils/timeout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = timeout;

  function timeout(ms, rejectionMessage) {
    return new Promise(function (resolve, reject) {
      if (typeof rejectionMessage === 'string') {
        setTimeout(function () {
          return reject(new Error(rejectionMessage));
        }, ms);
      } else {
        setTimeout(resolve, ms);
      }
    });
  }
});